import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { useIntl } from 'gatsby-plugin-intl'

//ICONS
import { LinkedIn, Instagram } from '@material-ui/icons'
import logo from '../images/Logo_big_banner.png'

//STYLES
import '../styles/footer.css'

const Footer = () => {
  // Page queries dont work on components only Static queries as this one.
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { key: { eq: "footer" } } }) {
        edges {
          node {
            frontmatter {
              whatWeDoList {
                name
                path
              }
              partnersList {
                name
                path
              }
              contactList {
                name
              }
              aboutUsList {
                name
                path
              }
            }
          }
        }
      }
    }
  `)
  const intl = useIntl()
  const locale = intl.locale !== 'sv' ? `/${intl.locale}` : ''

  let footerArray = data.allMarkdownRemark.edges[0].node.frontmatter
  let partnerList = footerArray.partnersList.map((item, i) => (
    <ul key={i}>
      <li key={i}>
        {' '}
        <a href={item.path}>{item.name}</a>{' '}
      </li>
    </ul>
  ))

  let contactList = footerArray.contactList.map((item, i) => (
    <ul key={i}>
      <li key={i} className="contact-info">
        {ReactHtmlParser(item.name)}
      </li>
    </ul>
  ))

  return (
    <div id="footer">
      <footer>
        <div className="logo-wrap">
          <div className="logo-flex">
            <img alt="logo" className="logo" src={logo}></img>
          </div>
        </div>
        <div className="social-media">
          <a
            aria-label="Linkedin icon"
            href="https://www.linkedin.com/company/cloud-enablers-ab/"
          >
            <LinkedIn className="social" />
          </a>
          <a
            aria-label="Instagram icon"
            href="https://www.instagram.com/cloudenablers/"
          >
            <Instagram color="secondary" className="social" />
          </a>
        </div>
        <div className="footer-section">
          <div className="links">
            <h3 className="link-title">
              {intl.formatMessage({ id: 'footer.whatwedo' })}
              <div className="orange-border"></div>
            </h3>
            <ul>
              <li>
                {' '}
                <Link to={`${locale}/digital-innovation`}>
                  {intl.formatMessage({ id: 'footer.DigitalInnovation' })}
                </Link>{' '}
              </li>
              <li>
                {' '}
                <Link to={`${locale}/change-management`}>
                  {intl.formatMessage({ id: 'footer.ChangeManagement' })}
                </Link>{' '}
              </li>
              <li>
                {' '}
                <Link to={`${locale}/cloud-strategy-architecture`}>
                  {intl.formatMessage({
                    id: 'footer.CloudStrategyArchitecture',
                  })}
                </Link>{' '}
              </li>
              <li>
                {' '}
                <Link to={`${locale}/product-delivery-process`}>
                  {intl.formatMessage({ id: 'footer.ProductDeliveryProcess' })}
                </Link>{' '}
              </li>
              <li>
                {' '}
                <Link to={`${locale}/design-build-operate`}>
                  {intl.formatMessage({ id: 'footer.DesignBuildAndOperate' })}
                </Link>{' '}
              </li>
              <li>
                {' '}
                <Link to={`${locale}/cloud-analytics`}>
                  {intl.formatMessage({ id: 'footer.CloudAnalytics' })}
                </Link>{' '}
              </li>
              <li>
                {' '}
                <Link to={`${locale}/cloud-integration`}>
                  {intl.formatMessage({ id: 'footer.CloudIntegration' })}
                </Link>{' '}
              </li>
            </ul>
          </div>

          <div className="links">
            <h3 className="link-title">
              {intl.formatMessage({ id: 'footer.partners' })}
              <div className="orange-border"></div>
            </h3>
            {partnerList}
          </div>
          <div className="links">
            <h3 className="link-title">
              {intl.formatMessage({ id: 'footer.about' })}
              <div className="orange-border"></div>
            </h3>
            <ul>
              <li>
                {' '}
                <Link to={`${locale}/about`}>
                  {intl.formatMessage({ id: 'footer.ourHistory' })}
                </Link>{' '}
              </li>
              <li>
                {' '}
                <Link to={`${locale}/about`}>
                  {intl.formatMessage({ id: 'footer.ourTeam' })}
                </Link>{' '}
              </li>
              <li>
                {' '}
                <Link to={`${locale}/privacypolicy`}>
                  {intl.formatMessage({ id: 'footer.privacyPolicy' })}
                </Link>{' '}
              </li>
            </ul>
          </div>
          <div className="links">
            <h3 className="link-title">
              {intl.formatMessage({ id: 'footer.contact' })}
              <div className="orange-border"></div>
            </h3>
            {contactList}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
